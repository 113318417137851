@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@layer base {
    html {
        font-family: 'Jost', system-ui, sans-serif;
    }
} */

@font-face {
    font-family: 'HeinekenBold';
    src: local('Heineken'),
        url('./assets/referrer/heineken/HeinekenSerif-Bold.otf')
            format('opentype');
}

h1,
h2,
h3,
h4 {
    font-family: 'Merriweather', sans-serif;
    font-weight: 400 !important;
}

body {
    margin: 0;
    font-family: 'Jost', system-ui, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace, 'Lato';
}

/* Styling for full page apps is fiddly - this pattern comes from this blog https://geon.github.io/programming/2016/02/24/flexbox-full-page-web-app-layout */
html,
body,
.full-screen {
    height: 100%;
}

.flex-container {
    display: flex;
    overflow: hidden;
}

.flex-container > * {
    flex: 0 0 auto;
    overflow: auto;
}

.stretchy {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.column {
    flex-direction: column;
}

html,
body,
.full-screen {
    height: 100%;
}

body {
    margin: 0;
}

.full-screen {
    display: flex;
    overflow: hidden;
    flex-direction: column;
}

.full-screen > * {
    flex: 0 0 auto;
    overflow: auto;
}

.full-screen > .header,
.full-screen > .footer {
    height: 50px;
    color: white;
}

.full-screen > .main {
    flex: 1 1 auto;
    display: flex;
    overflow: hidden;
}

.full-screen > .main > * {
    /* Getting this layout correct with infinite scroll is black magic. I've disbaled this becauase it was stopping flex-wrap from working with the content search controls. */
    /* flex: 0 0 auto; */
    overflow: auto;
}

.full-screen > .main > .left,
.full-screen > .main > .right {
    width: 200px;
    background: #eee;
}

.full-screen > .main > .middle {
    flex: 1 1 auto;
}

/* HUBSPOT FORM STYLES
   By default the hubspot form is unstyled - we do that ourselves here
   but sadly we cannot use tailwind css to do this. */
form.hs-form {
    margin: 0rem;
}

.hs-form-field {
    color: rgb(55, 65, 81);
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.hs-form-field label {
}

.hs-form-field legend {
}

.hs-form-field input {
    border-radius: 6px;
    border-color: rgb(209, 213, 219);
}

.hs-form-field textarea {
    border-radius: 6px;
    border-color: rgb(209, 213, 219);
}

.hs-submit {
}

/* END HUBSPOT FORM STYLES */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~@fortawesome/fontawesome-free/css/all.css';

@layer components {
    /* This button can have a number of states: Enabled, Disabled, Hover, Focused, Selected.

       See here https://material.io/design/interaction/states.html#usage for more information

       There is clearly some complexity around representing all these states and extracting a component - for now we are keeping it relatively simple
     */
    .btn-icon {
        @apply w-10 rounded-full p-2 text-gray-600 transition-all duration-150 ease-linear hover:shadow-md;
    }

    .btn-icon-not-selected-blue {
        @apply hover:bg-blue-200 hover:text-gray-900;
    }

    .btn-icon-selected-blue {
        @apply border-blue-700 bg-blue-700 text-blue-200 hover:bg-blue-200 hover:text-blue-700;
    }

    .btn-icon-queued-blue {
        @apply border-blue-700 bg-blue-700 text-blue-200 opacity-75 hover:bg-blue-200 hover:text-blue-700;
    }

    .btn-icon-selected-primary {
        @apply border-primary-500 bg-primary-500 text-primary-200 hover:bg-primary-200 hover:text-primary-500;
    }

    .btn-icon-not-selected-primary {
        @apply hover:bg-primary-200 hover:text-gray-900;
    }

    .btn-icon-queued-primary {
        @apply border-primary-500 bg-primary-500 text-primary-200 opacity-75 hover:bg-primary-200 hover:text-primary-500;
    }

    .btn-icon-not-selected-red {
        @apply hover:bg-red-200 hover:text-red-900;
    }

    .btn-icon-selected-red {
        @apply bg-red-700 text-red-200 hover:bg-red-200 hover:text-red-900;
    }

    .btn-icon-queued-red {
        @apply bg-red-700 text-red-200 opacity-75 hover:bg-red-200 hover:text-red-900;
    }

    .btn-icon-not-selected-yellow {
        @apply hover:bg-yellow-200 hover:text-yellow-900;
    }

    .btn-icon-selected-yellow {
        @apply bg-yellow-500 text-yellow-200 hover:bg-yellow-200 hover:text-yellow-900;
    }

    .btn-icon-queued-yellow {
        @apply bg-yellow-500 text-yellow-200 opacity-75 hover:bg-yellow-200 hover:text-yellow-900;
    }

    .btn-icon-not-selected-green {
        @apply hover:bg-green-200 hover:text-green-900;
    }

    .btn-icon-selected-green {
        @apply bg-green-700 text-green-200;
    }

    .btn-icon-selected-orange {
        @apply bg-gradient-to-t from-red-500 to-orange-500 text-orange-200 hover:bg-orange-200 hover:text-orange-900;
    }

    .btn-icon-not-selected-orange {
        @apply hover:bg-orange-200 hover:text-orange-900;
    }

    .btn-icon-loading-orange {
        @apply animate-pulse bg-gradient-to-t from-red-200 from-red-500 to-orange-200 to-orange-500 text-orange-200 hover:bg-gradient-to-t hover:text-orange-900;
    }

    .btn-icon-loading-yellow {
        @apply animate-pulse bg-gradient-to-t from-yellow-200 from-yellow-400 to-yellow-400 to-yellow-500 text-yellow-800 hover:bg-gradient-to-t hover:text-yellow-200;
    }

    .btn-icon-loading-green {
        @apply animate-pulse bg-gradient-to-t from-green-200 from-green-400 to-green-400 to-green-500 text-green-800 hover:bg-gradient-to-t hover:text-green-200;
    }

    .btn-icon-loading-blue {
        @apply animate-pulse bg-gradient-to-t from-blue-200 from-blue-400 to-blue-400 to-blue-500 text-blue-800 hover:bg-gradient-to-t hover:text-blue-200;
    }

    .btn-icon-loading-primary {
        @apply animate-pulse bg-gradient-to-t from-primary-200 from-primary-400 to-primary-400 to-primary-500 text-primary-800 hover:bg-gradient-to-t hover:text-primary-200;
    }

    .btn-icon-loading-red {
        @apply animate-pulse bg-gradient-to-t from-red-200 from-red-400 to-red-400 to-red-500 text-red-800 hover:bg-gradient-to-t hover:text-red-200;
    }
}

.progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

/** ********************* AMPLILFY STYLE OVERRIDES *************************** */
/* There is a full list of Amplify UI CSS variables here
 * https://ui.docs.amplify.aws/react/theming/css-variables
 */

/* All components have a class name starting with `amplify` */

/* Define this before variations on primary, etc as order matters here. */
.amplify-button:hover {
    background: #fbf0e8;
}

/* These classes override the more generic amplify-button class */
.amplify-button:hover {
    background: #f1f9fe;
}

.amplify-button--primary {
    background: #1971b0;
}

.amplify-button--primary:hover {
    background: #164d76;
}

/* Remove the shadow on the signin box*/
[data-amplify-authenticator] [data-amplify-router] {
    box-shadow: none;
}

/** ********************************************************************* */

.arwenTooltip {
    font-size: 14px !important;
    pointer-events: auto !important;
    overflow: hidden;
}

.arwenTooltip :hover {
    font-size: 14px !important;
    visibility: visible !important;
    opacity: 1 !important;
}

/* GOOGLE SIGNIN BUTTON STYLES */
.google-signin {
    content: url('assets/btn_google_signin_dark_normal_web.png');
}

.google-signin:hover {
    content: url('assets/btn_google_signin_dark_focus_web.png');
}

.google-signin:active {
    content: url('assets/btn_google_signin_dark_pressed_web.png');
}

.tiktok {
    color: #111111;

    filter: drop-shadow(2px 0px 0px #fd3e3e) drop-shadow(-2px -2px 0px #4de8f4);
}

.preset-dashboard iframe {
    width: 100%;
    height: 100%;
}

/* Label styling */
.label {
    position: absolute;
    /* font-size: 14px; */
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Default label */
.default {
    opacity: 1;
}

/* Hover label */
.hover {
    opacity: 0;
    transform: scale(0.8);
}

/* Show hover label on hover */
.badge:hover .default {
    opacity: 0;
    transform: scale(0.8);
}

.badge:hover .hover {
    opacity: 1;
    transform: scale(1);
}
